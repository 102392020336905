define("manage/components/manage/social-media", ["exports", "@ember/component", "@glimmer/component", "models/transforms/social-media-links", "@ember/object", "@glimmer/tracking", "@ember/template-factory"], function (_exports, _component, _component2, _socialMediaLinks, _object, _tracking, _templateFactory) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _class, _descriptor;
  function _initializerDefineProperty(e, i, r, l) { r && Object.defineProperty(e, i, { enumerable: r.enumerable, configurable: r.configurable, writable: r.writable, value: r.initializer ? r.initializer.call(l) : void 0 }); }
  function _defineProperty(e, r, t) { return (r = _toPropertyKey(r)) in e ? Object.defineProperty(e, r, { value: t, enumerable: !0, configurable: !0, writable: !0 }) : e[r] = t, e; }
  function _toPropertyKey(t) { var i = _toPrimitive(t, "string"); return "symbol" == typeof i ? i : i + ""; }
  function _toPrimitive(t, r) { if ("object" != typeof t || !t) return t; var e = t[Symbol.toPrimitive]; if (void 0 !== e) { var i = e.call(t, r || "default"); if ("object" != typeof i) return i; throw new TypeError("@@toPrimitive must return a primitive value."); } return ("string" === r ? String : Number)(t); }
  function _applyDecoratedDescriptor(i, e, r, n, l) { var a = {}; return Object.keys(n).forEach(function (i) { a[i] = n[i]; }), a.enumerable = !!a.enumerable, a.configurable = !!a.configurable, ("value" in a || a.initializer) && (a.writable = !0), a = r.slice().reverse().reduce(function (r, n) { return n(i, e, r) || r; }, a), l && void 0 !== a.initializer && (a.value = a.initializer ? a.initializer.call(l) : void 0, a.initializer = void 0), void 0 === a.initializer ? (Object.defineProperty(i, e, a), null) : a; }
  function _initializerWarningHelper(r, e) { throw Error("Decorating class property failed. Please ensure that transform-class-properties is enabled and runs after the decorators transform."); }
  const __COLOCATED_TEMPLATE__ = (0, _templateFactory.createTemplateFactory)(
  /*
    <Manage::FieldContainer @title={{@title}} @isDisabled={{@isDisabled}} @isSaveDisabled={{not this._value}}
    @editablePanel={{@editablePanel}} @value={{@value}} @onEdit={{@onEdit}} @onSave={{this.save}} @onCancel={{@onCancel}}
    class="manage-social-media" ...attributes>
    <:view>
      {{#if this.hasPairs}}
        {{#each this.pairs as | pair | }}
          {{#if pair.value}}
            <div class="name">
              {{t (concat "social-media." pair.key)}}
            </div>
            <div class="value">
              {{pair.value}}
            </div>
          {{/if}}
        {{/each}}
      {{else}}
        <div class='alert alert-secondary' role='alert'>
          {{t "manage-section.no-social-media-links"}}
        </div>
      {{/if}}
    </:view>
  
    <:edit>
      {{#each this.pairs as | pair | }}
        <Label @value={{t (concat "social-media." pair.key)}} as |id|>
          <Input::Text class="form-control" id={{id}} @value={{pair.value}} @onChange={{fn this.change pair.key}} />
        </Label>
      {{/each}}
    </:edit>
  </Manage::FieldContainer>
  */
  {
    "id": "XOn5O0gU",
    "block": "[[[8,[39,0],[[24,0,\"manage-social-media\"],[17,1]],[[\"@title\",\"@isDisabled\",\"@isSaveDisabled\",\"@editablePanel\",\"@value\",\"@onEdit\",\"@onSave\",\"@onCancel\"],[[30,2],[30,3],[28,[37,1],[[30,0,[\"_value\"]]],null],[30,4],[30,5],[30,6],[30,0,[\"save\"]],[30,7]]],[[\"view\",\"edit\"],[[[[1,\"\\n\"],[41,[30,0,[\"hasPairs\"]],[[[42,[28,[37,5],[[28,[37,5],[[30,0,[\"pairs\"]]],null]],null],null,[[[41,[30,8,[\"value\"]],[[[1,\"          \"],[10,0],[14,0,\"name\"],[12],[1,\"\\n            \"],[1,[28,[35,7],[[28,[37,8],[\"social-media.\",[30,8,[\"key\"]]],null]],null]],[1,\"\\n          \"],[13],[1,\"\\n          \"],[10,0],[14,0,\"value\"],[12],[1,\"\\n            \"],[1,[30,8,[\"value\"]]],[1,\"\\n          \"],[13],[1,\"\\n\"]],[]],null]],[8]],null]],[]],[[[1,\"      \"],[10,0],[14,0,\"alert alert-secondary\"],[14,\"role\",\"alert\"],[12],[1,\"\\n        \"],[1,[28,[35,7],[\"manage-section.no-social-media-links\"],null]],[1,\"\\n      \"],[13],[1,\"\\n\"]],[]]],[1,\"  \"]],[]],[[[1,\"\\n\"],[42,[28,[37,5],[[28,[37,5],[[30,0,[\"pairs\"]]],null]],null],null,[[[1,\"      \"],[8,[39,10],null,[[\"@value\"],[[28,[37,7],[[28,[37,8],[\"social-media.\",[30,9,[\"key\"]]],null]],null]]],[[\"default\"],[[[[1,\"\\n        \"],[8,[39,11],[[24,0,\"form-control\"],[16,1,[30,10]]],[[\"@value\",\"@onChange\"],[[30,9,[\"value\"]],[28,[37,12],[[30,0,[\"change\"]],[30,9,[\"key\"]]],null]]],null],[1,\"\\n      \"]],[10]]]]],[1,\"\\n\"]],[9]],null],[1,\"  \"]],[]]]]]],[\"&attrs\",\"@title\",\"@isDisabled\",\"@editablePanel\",\"@value\",\"@onEdit\",\"@onCancel\",\"pair\",\"pair\",\"id\"],false,[\"manage/field-container\",\"not\",\":view\",\"if\",\"each\",\"-track-array\",\"div\",\"t\",\"concat\",\":edit\",\"label\",\"input/text\",\"fn\"]]",
    "moduleName": "manage/components/manage/social-media.hbs",
    "isStrictMode": false
  });
  let ManageSocialMedia = _exports.default = (_class = class ManageSocialMedia extends _component2.default {
    constructor(...args) {
      super(...args);
      _initializerDefineProperty(this, "_value", _descriptor, this);
    }
    get hasPairs() {
      return this.pairs?.filter(a => a.value.trim()).length > 0;
    }
    get pairs() {
      let result = [];
      for (const field of _socialMediaLinks.SocialMediaList) {
        result.push({
          key: field,
          value: this.args.value?.[field]?.trim?.() ?? ""
        });
      }
      return result;
    }
    change(key, value) {
      value = value.trim();
      if (value.indexOf("http") != "0" && value != "") {
        value = `https://${value}`;
      }
      if (!this._value) {
        this._value = new _socialMediaLinks.SocialMediaLinks(this.args.value);
      }
      this._value[key] = encodeURI(value);
    }
    save() {
      if (!this._value) {
        return;
      }
      return this.args.onSave?.(this._value);
    }
  }, (_descriptor = _applyDecoratedDescriptor(_class.prototype, "_value", [_tracking.tracked], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _applyDecoratedDescriptor(_class.prototype, "change", [_object.action], Object.getOwnPropertyDescriptor(_class.prototype, "change"), _class.prototype), _applyDecoratedDescriptor(_class.prototype, "save", [_object.action], Object.getOwnPropertyDescriptor(_class.prototype, "save"), _class.prototype)), _class);
  (0, _component.setComponentTemplate)(__COLOCATED_TEMPLATE__, ManageSocialMedia);
});