define("spaces/components/editor/text-color", ["exports", "@ember/component", "@glimmer/component", "@glimmer/tracking", "@ember/object", "@ember/template-factory"], function (_exports, _component, _component2, _tracking, _object, _templateFactory) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _class, _descriptor;
  function _initializerDefineProperty(e, i, r, l) { r && Object.defineProperty(e, i, { enumerable: r.enumerable, configurable: r.configurable, writable: r.writable, value: r.initializer ? r.initializer.call(l) : void 0 }); }
  function _defineProperty(e, r, t) { return (r = _toPropertyKey(r)) in e ? Object.defineProperty(e, r, { value: t, enumerable: !0, configurable: !0, writable: !0 }) : e[r] = t, e; }
  function _toPropertyKey(t) { var i = _toPrimitive(t, "string"); return "symbol" == typeof i ? i : i + ""; }
  function _toPrimitive(t, r) { if ("object" != typeof t || !t) return t; var e = t[Symbol.toPrimitive]; if (void 0 !== e) { var i = e.call(t, r || "default"); if ("object" != typeof i) return i; throw new TypeError("@@toPrimitive must return a primitive value."); } return ("string" === r ? String : Number)(t); }
  function _applyDecoratedDescriptor(i, e, r, n, l) { var a = {}; return Object.keys(n).forEach(function (i) { a[i] = n[i]; }), a.enumerable = !!a.enumerable, a.configurable = !!a.configurable, ("value" in a || a.initializer) && (a.writable = !0), a = r.slice().reverse().reduce(function (r, n) { return n(i, e, r) || r; }, a), l && void 0 !== a.initializer && (a.value = a.initializer ? a.initializer.call(l) : void 0, a.initializer = void 0), void 0 === a.initializer ? (Object.defineProperty(i, e, a), null) : a; }
  function _initializerWarningHelper(r, e) { throw Error("Decorating class property failed. Please ensure that transform-class-properties is enabled and runs after the decorators transform."); }
  const __COLOCATED_TEMPLATE__ = (0, _templateFactory.createTemplateFactory)(
  /*
    
    <Input::BsColorPicker class="text-color-selector" @label={{t "option-panel.color"}} @onChange={{this.changeColor}} @value={{this.color}} />
  */
  {
    "id": "BrOdNnbL",
    "block": "[[[1,\"\\n  \"],[8,[39,0],[[24,0,\"text-color-selector\"]],[[\"@label\",\"@onChange\",\"@value\"],[[28,[37,1],[\"option-panel.color\"],null],[30,0,[\"changeColor\"]],[30,0,[\"color\"]]]],null]],[],false,[\"input/bs-color-picker\",\"t\"]]",
    "moduleName": "spaces/components/editor/text-color.hbs",
    "isStrictMode": false
  });
  let EditorTextColor = _exports.default = (_class = class EditorTextColor extends _component2.default {
    constructor(...args) {
      super(...args);
      _initializerDefineProperty(this, "_color", _descriptor, this);
    }
    get color() {
      if (this._color) {
        return this._color;
      }
      return this.args.value?.color ?? "";
    }
    changeColor(value) {
      this._color = value;
      this.triggerChange();
    }
    triggerChange() {
      const newOptions = {
        color: this.color
      };
      return this.args.onChange?.(newOptions);
    }
  }, (_descriptor = _applyDecoratedDescriptor(_class.prototype, "_color", [_tracking.tracked], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _applyDecoratedDescriptor(_class.prototype, "changeColor", [_object.action], Object.getOwnPropertyDescriptor(_class.prototype, "changeColor"), _class.prototype)), _class);
  (0, _component.setComponentTemplate)(__COLOCATED_TEMPLATE__, EditorTextColor);
});