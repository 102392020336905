define("spaces-view/components/view/source-attribute", ["exports", "@ember/component", "@glimmer/component", "@ember/object", "@glimmer/tracking", "@ember/template-factory"], function (_exports, _component, _component2, _object, _tracking, _templateFactory) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _class, _descriptor;
  function _initializerDefineProperty(e, i, r, l) { r && Object.defineProperty(e, i, { enumerable: r.enumerable, configurable: r.configurable, writable: r.writable, value: r.initializer ? r.initializer.call(l) : void 0 }); }
  function _defineProperty(e, r, t) { return (r = _toPropertyKey(r)) in e ? Object.defineProperty(e, r, { value: t, enumerable: !0, configurable: !0, writable: !0 }) : e[r] = t, e; }
  function _toPropertyKey(t) { var i = _toPrimitive(t, "string"); return "symbol" == typeof i ? i : i + ""; }
  function _toPrimitive(t, r) { if ("object" != typeof t || !t) return t; var e = t[Symbol.toPrimitive]; if (void 0 !== e) { var i = e.call(t, r || "default"); if ("object" != typeof i) return i; throw new TypeError("@@toPrimitive must return a primitive value."); } return ("string" === r ? String : Number)(t); }
  function _applyDecoratedDescriptor(i, e, r, n, l) { var a = {}; return Object.keys(n).forEach(function (i) { a[i] = n[i]; }), a.enumerable = !!a.enumerable, a.configurable = !!a.configurable, ("value" in a || a.initializer) && (a.writable = !0), a = r.slice().reverse().reduce(function (r, n) { return n(i, e, r) || r; }, a), l && void 0 !== a.initializer && (a.value = a.initializer ? a.initializer.call(l) : void 0, a.initializer = void 0), void 0 === a.initializer ? (Object.defineProperty(i, e, a), null) : a; }
  function _initializerWarningHelper(r, e) { throw Error("Decorating class property failed. Please ensure that transform-class-properties is enabled and runs after the decorators transform."); }
  const __COLOCATED_TEMPLATE__ = (0, _templateFactory.createTemplateFactory)(
  /*
    <div
    class="source-attribute"
    {{on "props-update" this.updateProps}}
    data-path="{{@path}}.data.sourceAttribute"
    data-type="attribute"
    data-name={{@value.name}}
    data-gid={{@value.gid}}
    data-editor="source-attribute"
    ...attributes
  >
    {{#if this.attributeValue}}
      {{#if this.isBool}}
        {{yield (t (concat "boolean-" this.attributeValue)) this.hasValue}}
      {{else if this.hasBlocks}}
        {{yield this.attributeValue this.hasValue to="blocks"}}
      {{else}}
        {{yield this.attributeValue this.hasValue}}
      {{/if}}
    {{/if}}
  </div>
  */
  {
    "id": "GTij/sOl",
    "block": "[[[11,0],[24,0,\"source-attribute\"],[16,\"data-path\",[29,[[30,1],\".data.sourceAttribute\"]]],[24,\"data-type\",\"attribute\"],[16,\"data-name\",[30,2,[\"name\"]]],[16,\"data-gid\",[30,2,[\"gid\"]]],[24,\"data-editor\",\"source-attribute\"],[17,3],[4,[38,1],[\"props-update\",[30,0,[\"updateProps\"]]],null],[12],[1,\"\\n\"],[41,[30,0,[\"attributeValue\"]],[[[41,[30,0,[\"isBool\"]],[[[1,\"      \"],[18,4,[[28,[37,4],[[28,[37,5],[\"boolean-\",[30,0,[\"attributeValue\"]]],null]],null],[30,0,[\"hasValue\"]]]],[1,\"\\n\"]],[]],[[[41,[30,0,[\"hasBlocks\"]],[[[1,\"      \"],[18,5,[[30,0,[\"attributeValue\"]],[30,0,[\"hasValue\"]]]],[1,\"\\n\"]],[]],[[[1,\"      \"],[18,4,[[30,0,[\"attributeValue\"]],[30,0,[\"hasValue\"]]]],[1,\"\\n    \"]],[]]]],[]]]],[]],null],[13]],[\"@path\",\"@value\",\"&attrs\",\"&default\",\"&blocks\"],false,[\"div\",\"on\",\"if\",\"yield\",\"t\",\"concat\"]]",
    "moduleName": "spaces-view/components/view/source-attribute.hbs",
    "isStrictMode": false
  });
  /**
    Copyright: © 2015-2024 GISCollective
    License: Subject to the terms of the AFFERO GENERAL PUBLIC LICENSE, as written in the included COPYING file.
  */
  let ViewSourceAttributeComponent = _exports.default = (_class = class ViewSourceAttributeComponent extends _component2.default {
    constructor(...args) {
      super(...args);
      _initializerDefineProperty(this, "_value", _descriptor, this);
    }
    get value() {
      if (this._value) {
        return this._value;
      }
      return this.args.value?.data?.sourceAttribute ?? {};
    }
    get defaultAttributeValue() {
      if (this.args.isEditor) {
        return 'no value';
      }
      return null;
    }
    get hasValue() {
      if (!this.args.record || !this.value.field) {
        return false;
      }
      return true;
    }
    get attributeValue() {
      if (!this.hasValue) {
        return this.defaultAttributeValue;
      }
      return (0, _object.get)(this.args.record, 'attributes.' + this.value.field);
    }
    get hasBlocks() {
      return Array.isArray(this.attributeValue?.blocks);
    }
    get isBool() {
      return typeof this.attributeValue == 'boolean';
    }
    updateProps(event) {
      this._value = event.detail.value;
    }
  }, (_descriptor = _applyDecoratedDescriptor(_class.prototype, "_value", [_tracking.tracked], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _applyDecoratedDescriptor(_class.prototype, "updateProps", [_object.action], Object.getOwnPropertyDescriptor(_class.prototype, "updateProps"), _class.prototype)), _class);
  (0, _component.setComponentTemplate)(__COLOCATED_TEMPLATE__, ViewSourceAttributeComponent);
});