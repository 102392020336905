define("spaces-view/lib/page-model", ["exports", "@glimmer/tracking", "rsvp", "core/lib/page-state", "core/lib/slug", "models/lib/fetch-source"], function (_exports, _tracking, _rsvp, _pageState, _slug, _fetchSource) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.validVariables = _exports.PageModel = _exports.PageGroup = _exports.PageData = void 0;
  var _class, _descriptor, _descriptor2, _descriptor3, _descriptor4, _class2, _descriptor5, _descriptor6, _descriptor7, _descriptor8, _descriptor9, _descriptor10, _descriptor11, _class3, _descriptor12, _descriptor13, _descriptor14, _descriptor15, _descriptor16;
  /**
    Copyright: © 2015-2024 GISCollective
    License: Subject to the terms of the AFFERO GENERAL PUBLIC LICENSE, as written in the included COPYING file.
  */
  function _initializerDefineProperty(e, i, r, l) { r && Object.defineProperty(e, i, { enumerable: r.enumerable, configurable: r.configurable, writable: r.writable, value: r.initializer ? r.initializer.call(l) : void 0 }); }
  function _defineProperty(e, r, t) { return (r = _toPropertyKey(r)) in e ? Object.defineProperty(e, r, { value: t, enumerable: !0, configurable: !0, writable: !0 }) : e[r] = t, e; }
  function _toPropertyKey(t) { var i = _toPrimitive(t, "string"); return "symbol" == typeof i ? i : i + ""; }
  function _toPrimitive(t, r) { if ("object" != typeof t || !t) return t; var e = t[Symbol.toPrimitive]; if (void 0 !== e) { var i = e.call(t, r || "default"); if ("object" != typeof i) return i; throw new TypeError("@@toPrimitive must return a primitive value."); } return ("string" === r ? String : Number)(t); }
  function _applyDecoratedDescriptor(i, e, r, n, l) { var a = {}; return Object.keys(n).forEach(function (i) { a[i] = n[i]; }), a.enumerable = !!a.enumerable, a.configurable = !!a.configurable, ("value" in a || a.initializer) && (a.writable = !0), a = r.slice().reverse().reduce(function (r, n) { return n(i, e, r) || r; }, a), l && void 0 !== a.initializer && (a.value = a.initializer ? a.initializer.call(l) : void 0, a.initializer = void 0), void 0 === a.initializer ? (Object.defineProperty(i, e, a), null) : a; }
  function _initializerWarningHelper(r, e) { throw Error("Decorating class property failed. Please ensure that transform-class-properties is enabled and runs after the decorators transform."); }
  let PageData = _exports.PageData = (_class = class PageData {
    constructor() {
      _initializerDefineProperty(this, "changeCount", _descriptor, this);
      _initializerDefineProperty(this, "selectedModel", _descriptor2, this);
      _initializerDefineProperty(this, "selectedType", _descriptor3, this);
      _initializerDefineProperty(this, "selectedId", _descriptor4, this);
    }
  }, (_descriptor = _applyDecoratedDescriptor(_class.prototype, "changeCount", [_tracking.tracked], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: function () {
      return 0;
    }
  }), _descriptor2 = _applyDecoratedDescriptor(_class.prototype, "selectedModel", [_tracking.tracked], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor3 = _applyDecoratedDescriptor(_class.prototype, "selectedType", [_tracking.tracked], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor4 = _applyDecoratedDescriptor(_class.prototype, "selectedId", [_tracking.tracked], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  })), _class);
  const validVariables = _exports.validVariables = ['feature', 'map', 'campaign', 'icon', 'icon-set', 'article'];
  let PageGroup = _exports.PageGroup = (_class2 = class PageGroup {
    constructor() {
      _initializerDefineProperty(this, "path", _descriptor5, this);
      _initializerDefineProperty(this, "page", _descriptor6, this);
      _initializerDefineProperty(this, "store", _descriptor7, this);
      _initializerDefineProperty(this, "data", _descriptor8, this);
      _initializerDefineProperty(this, "pageId", _descriptor9, this);
      _initializerDefineProperty(this, "space", _descriptor10, this);
      _initializerDefineProperty(this, "tracking", _descriptor11, this);
    }
    get slug() {
      return this.page?.slug ?? '';
    }
    get hasVariable() {
      return this.slug.includes(':');
    }
    get variableName() {
      return (0, _slug.getVariablesFromPath)(this.slug)[0] ?? '';
    }
    get variableValue() {
      let value;
      const pathPieces = this.path?.split?.('/').filter?.(a => a.trim()) ?? [];
      const slugPieces = this.slug?.split?.('--') ?? [];
      slugPieces.forEach((item, index) => {
        if (!item.includes(':')) {
          return;
        }
        value = pathPieces[index];
      });
      return value ?? '';
    }
    get hasUnknownVariableValue() {
      return this.variableValue.startsWith(':');
    }
    fillState(state) {
      for (const col of this.page.cols) {
        col.fillState(state);
      }
    }
    async fetchSelectedModel() {
      if (this.store.isDestroyed) {
        return;
      }
      if (!this.hasVariable) {
        return;
      }
      const model = this.variableName.replace(':', '').replace('-id', '');
      const id = this.variableValue;
      if (!model) {
        return;
      }
      if (!this.variableValue) {
        return;
      }
      let record = await (0, _fetchSource.fetchSourceRecord)({
        model,
        id
      }, this.space, this.store);
      this.data[model] = record;
      this.data.selectedModel = record;
      this.data.selectedType = model;
      this.data.selectedId = id;
      await this.tracking?.addLoad?.(model, id);
    }
    async ensurePage() {
      if (this.page) {
        return;
      }
      this.page = await this.store.findRecord('page', this.pageId);
    }
    async fetch() {
      if (!this.pageId) {
        return;
      }
      await this.ensurePage();
      await this.page.fetchColsWithoutParams(this.data);
      await this.fetchSelectedModel();
      await this.page.fetchColsWithParams(this.data);
      const loadedData = await (0, _rsvp.hash)(this.data);
      for (const key of Object.keys(loadedData)) {
        this.data[key] = loadedData[key];
      }
      this.data.space = this.space;
      this.data.page = this.page;
      this.data.changeCount++;
    }
  }, (_descriptor5 = _applyDecoratedDescriptor(_class2.prototype, "path", [_tracking.tracked], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor6 = _applyDecoratedDescriptor(_class2.prototype, "page", [_tracking.tracked], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor7 = _applyDecoratedDescriptor(_class2.prototype, "store", [_tracking.tracked], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor8 = _applyDecoratedDescriptor(_class2.prototype, "data", [_tracking.tracked], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: function () {
      return new PageData();
    }
  }), _descriptor9 = _applyDecoratedDescriptor(_class2.prototype, "pageId", [_tracking.tracked], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor10 = _applyDecoratedDescriptor(_class2.prototype, "space", [_tracking.tracked], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor11 = _applyDecoratedDescriptor(_class2.prototype, "tracking", [_tracking.tracked], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  })), _class2);
  let PageModel = _exports.PageModel = (_class3 = class PageModel {
    constructor(store, space, path, tracking) {
      _initializerDefineProperty(this, "space", _descriptor12, this);
      _initializerDefineProperty(this, "store", _descriptor13, this);
      _initializerDefineProperty(this, "headData", _descriptor14, this);
      _initializerDefineProperty(this, "main", _descriptor15, this);
      _initializerDefineProperty(this, "panel", _descriptor16, this);
      this.store = store;
      this.space = space;
      this.main.store = store;
      this.main.path = path;
      this.main.space = space;
      this.main.tracking = tracking;
      this.main.pageId = (0, _slug.toPageId)(path, this.space);
    }
    fillState(state) {
      (0, _pageState.fillPageStateInModel)(state, this.main.data);
      this.main.fillState(state);
      if (!this.main.page.canShowFeaturePanel) {
        this.panel = null;
        return;
      }
      if (!this.main.data['state-feature-id']) {
        this.panel = null;
        return;
      }
      if (!this.panel) {
        this.panel = new PageGroup();
        this.panel.tracking = this.tracking;
      }
      this.panel.path = `/_panel/feature/${this.main.data['state-feature-id']}`;
      const pageId = (0, _slug.toPageId)(this.panel.path, this.space);
      if (this.panel?.pageId != pageId) {
        this.panel.page = null;
        this.panel.store = this.store;
        this.panel.space = this.space;
        this.panel.pageId = pageId;
      }
    }
    async fetch() {
      await this.main.fetch();
      await this.panel?.fetch();
      if (!this.space) {
        this.space = await this.main.page.space;
      }
    }
    static clearCache() {
      this.cachedModel = null;
    }
    static async getCachedModel(path, store, space, tracking) {
      if (this.cachedModel?.main?.path != path || this.cachedModel?.space?.isDestroyed) {
        this.cachedModel = new PageModel(store, space, path, tracking);
      }
      return this.cachedModel;
    }
    static async getPageModelFromPath(path, strState, store, space, router, tracking) {
      let pageModel = await PageModel.getCachedModel(path, store, space, tracking);
      if (!pageModel?.main?.pageId) {
        return;
      }
      await pageModel?.main?.ensurePage();
      if (pageModel?.main?.hasUnknownVariableValue) {
        const newPath = await (0, _slug.fillVariable)(path, pageModel?.main?.variableName, store);
        if (newPath) {
          router?.transitionTo?.(newPath);
          return {};
        }
        return;
      }
      const state = (0, _pageState.fromPageState)(strState);
      pageModel.fillState(state);
      if (pageModel?.main?.pageId) {
        try {
          await pageModel.fetch();
        } catch (err) {
          // eslint-disable-next-line no-console
          console.error(err);
          pageModel.main = null;
        }
      }
      return pageModel;
    }
  }, (_descriptor12 = _applyDecoratedDescriptor(_class3.prototype, "space", [_tracking.tracked], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor13 = _applyDecoratedDescriptor(_class3.prototype, "store", [_tracking.tracked], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor14 = _applyDecoratedDescriptor(_class3.prototype, "headData", [_tracking.tracked], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor15 = _applyDecoratedDescriptor(_class3.prototype, "main", [_tracking.tracked], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: function () {
      return new PageGroup();
    }
  }), _descriptor16 = _applyDecoratedDescriptor(_class3.prototype, "panel", [_tracking.tracked], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: function () {
      return null;
    }
  })), _class3);
});