define("manage/lib/SheetColData", ["exports", "@glimmer/tracking", "@ember/string"], function (_exports, _tracking, _string) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.SheetColData = void 0;
  _exports.attributeToSheetColData = attributeToSheetColData;
  var _class, _descriptor, _descriptor2, _descriptor3, _descriptor4, _descriptor5;
  /**
    Copyright: © 2015-2024 GISCollective
    License: Subject to the terms of the AFFERO GENERAL PUBLIC LICENSE, as written in the included COPYING file.
  */
  function _initializerDefineProperty(e, i, r, l) { r && Object.defineProperty(e, i, { enumerable: r.enumerable, configurable: r.configurable, writable: r.writable, value: r.initializer ? r.initializer.call(l) : void 0 }); }
  function _defineProperty(e, r, t) { return (r = _toPropertyKey(r)) in e ? Object.defineProperty(e, r, { value: t, enumerable: !0, configurable: !0, writable: !0 }) : e[r] = t, e; }
  function _toPropertyKey(t) { var i = _toPrimitive(t, "string"); return "symbol" == typeof i ? i : i + ""; }
  function _toPrimitive(t, r) { if ("object" != typeof t || !t) return t; var e = t[Symbol.toPrimitive]; if (void 0 !== e) { var i = e.call(t, r || "default"); if ("object" != typeof i) return i; throw new TypeError("@@toPrimitive must return a primitive value."); } return ("string" === r ? String : Number)(t); }
  function _applyDecoratedDescriptor(i, e, r, n, l) { var a = {}; return Object.keys(n).forEach(function (i) { a[i] = n[i]; }), a.enumerable = !!a.enumerable, a.configurable = !!a.configurable, ("value" in a || a.initializer) && (a.writable = !0), a = r.slice().reverse().reduce(function (r, n) { return n(i, e, r) || r; }, a), l && void 0 !== a.initializer && (a.value = a.initializer ? a.initializer.call(l) : void 0, a.initializer = void 0), void 0 === a.initializer ? (Object.defineProperty(i, e, a), null) : a; }
  function _initializerWarningHelper(r, e) { throw Error("Decorating class property failed. Please ensure that transform-class-properties is enabled and runs after the decorators transform."); }
  let SheetColData = _exports.SheetColData = (_class = class SheetColData {
    constructor(data) {
      _initializerDefineProperty(this, "name", _descriptor, this);
      _initializerDefineProperty(this, "valuePath", _descriptor2, this);
      _initializerDefineProperty(this, "width", _descriptor3, this);
      _initializerDefineProperty(this, "type", _descriptor4, this);
      _initializerDefineProperty(this, "editorType", _descriptor5, this);
      if (!data) {
        return;
      }
      if (data.name) {
        this.name = data.name;
      }
      if (data.valuePath) {
        this.valuePath = data.valuePath;
      }
      if (data.width) {
        this.width = data.width;
      }
      if (data.type) {
        this.type = data.type;
      }
      if (data.editorType) {
        this.editorType = data.editorType;
      }
      if (data.key) {
        this.key = data.key;
      }
      if (data.icon) {
        this.icon = data.icon;
      }
      if (data.options) {
        this.options = data.options;
      }
    }
    get style() {
      return (0, _string.htmlSafe)(`width: ${this.width}px`);
    }
    get typeClass() {
      if (!this.editorType) {
        return `editor-none`;
      }
      if (this.editorType.indexOf('inline') != -1) {
        return `editor-inline`;
      }
      return `editor-custom`;
    }
  }, (_descriptor = _applyDecoratedDescriptor(_class.prototype, "name", [_tracking.tracked], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor2 = _applyDecoratedDescriptor(_class.prototype, "valuePath", [_tracking.tracked], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor3 = _applyDecoratedDescriptor(_class.prototype, "width", [_tracking.tracked], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: function () {
      return 100;
    }
  }), _descriptor4 = _applyDecoratedDescriptor(_class.prototype, "type", [_tracking.tracked], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor5 = _applyDecoratedDescriptor(_class.prototype, "editorType", [_tracking.tracked], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  })), _class);
  function attributeToSheetColData(icon, attribute) {
    if (!icon || !attribute) {
      return null;
    }
    let editorType = 'inline-text';
    let options = {};
    if (attribute.type == 'integer') {
      editorType = 'inline-integer';
    }
    if (attribute.type == 'decimal') {
      editorType = 'inline-decimal';
    }
    if (attribute.type == 'long text') {
      editorType = 'blocks';
    }
    if (icon?.allowMany || ['options with other', 'options', 'boolean', 'date'].indexOf(attribute.type) != -1) {
      editorType = 'icon-attribute';
    }
    let type = 'icon-attribute';
    options.icon = icon;
    options.key = attribute?.name || attribute?.key;
    return new SheetColData({
      name: attribute?.name,
      valuePath: `attributes`,
      key: attribute?.name?.replace(/[^0-9a-z]/gi, '-').toLowerCase(),
      editorType,
      icon,
      options,
      type
    });
  }
});