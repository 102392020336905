define("spaces-view/components/view/social-media", ["exports", "@ember/component", "@glimmer/component", "@ember/template-factory"], function (_exports, _component, _component2, _templateFactory) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  const __COLOCATED_TEMPLATE__ = (0, _templateFactory.createTemplateFactory)(
  /*
    <View::Base::SourceRecord @space={{@space}} @path={{@path}} @model={{@model}} @value={{@value}} @isEditor={{@isEditor}}
    data-preferred-model="team" as |team|>
    <Container @value={{@value.data.container}} @deviceSize={{@deviceSize}} class="social-media use-highlights"
      data-name={{@value.name}} data-gid={{@value.gid}} data-path="{{@path}}.data.container" ...attributes>
      {{#each-in team.socialMediaLinks.niceLinks as |key value| }}
        {{#if (or @isEditor value)}}
          <TextWithOptions @to={{value}} target="_blank" @tag="a" @deviceSize={{@deviceSize}} @options={{@value.data.icon}}
            data-path={{concat @path ".data.icon"}} data-type="icon" data-name={{if @path @value.name}}
            data-gid={{@value.gid}} data-editor="text-color">{{!
            }}
            <FaIcon @icon={{lowercase key}} @prefix="fab" />{{!
          }}
          </TextWithOptions>
        {{/if}}
      {{/each-in}}
    </Container>
  </View::Base::SourceRecord>
  */
  {
    "id": "aGZbfr+P",
    "block": "[[[8,[39,0],[[24,\"data-preferred-model\",\"team\"]],[[\"@space\",\"@path\",\"@model\",\"@value\",\"@isEditor\"],[[30,1],[30,2],[30,3],[30,4],[30,5]]],[[\"default\"],[[[[1,\"\\n  \"],[8,[39,1],[[24,0,\"social-media use-highlights\"],[16,\"data-name\",[30,4,[\"name\"]]],[16,\"data-gid\",[30,4,[\"gid\"]]],[16,\"data-path\",[29,[[30,2],\".data.container\"]]],[17,7]],[[\"@value\",\"@deviceSize\"],[[30,4,[\"data\",\"container\"]],[30,8]]],[[\"default\"],[[[[1,\"\\n\"],[42,[28,[37,3],[[30,6,[\"socialMediaLinks\",\"niceLinks\"]]],null],null,[[[41,[28,[37,5],[[30,5],[30,9]],null],[[[1,\"        \"],[8,[39,6],[[24,\"target\",\"_blank\"],[16,\"data-path\",[28,[37,7],[[30,2],\".data.icon\"],null]],[24,\"data-type\",\"icon\"],[16,\"data-name\",[52,[30,2],[30,4,[\"name\"]]]],[16,\"data-gid\",[30,4,[\"gid\"]]],[24,\"data-editor\",\"text-color\"]],[[\"@to\",\"@tag\",\"@deviceSize\",\"@options\"],[[30,9],\"a\",[30,8],[30,4,[\"data\",\"icon\"]]]],[[\"default\"],[[[[1,\"\\n          \"],[8,[39,8],null,[[\"@icon\",\"@prefix\"],[[28,[37,9],[[30,10]],null],\"fab\"]],null],[1,\"\\n        \"]],[]]]]],[1,\"\\n\"]],[]],null]],[9,10]],null],[1,\"  \"]],[]]]]],[1,\"\\n\"]],[6]]]]]],[\"@space\",\"@path\",\"@model\",\"@value\",\"@isEditor\",\"team\",\"&attrs\",\"@deviceSize\",\"value\",\"key\"],false,[\"view/base/source-record\",\"container\",\"each\",\"-each-in\",\"if\",\"or\",\"text-with-options\",\"concat\",\"fa-icon\",\"lowercase\"]]",
    "moduleName": "spaces-view/components/view/social-media.hbs",
    "isStrictMode": false
  });
  class ViewSocialMedia extends _component2.default {}
  _exports.default = ViewSocialMedia;
  (0, _component.setComponentTemplate)(__COLOCATED_TEMPLATE__, ViewSocialMedia);
});