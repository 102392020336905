define("spaces/components/page-frame-edit", ["exports", "@ember/component", "@glimmer/component", "@ember/object", "@ember/runloop", "@glimmer/tracking", "@ember/template-factory"], function (_exports, _component, _component2, _object, _runloop, _tracking, _templateFactory) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _class;
  function _applyDecoratedDescriptor(i, e, r, n, l) { var a = {}; return Object.keys(n).forEach(function (i) { a[i] = n[i]; }), a.enumerable = !!a.enumerable, a.configurable = !!a.configurable, ("value" in a || a.initializer) && (a.writable = !0), a = r.slice().reverse().reduce(function (r, n) { return n(i, e, r) || r; }, a), l && void 0 !== a.initializer && (a.value = a.initializer ? a.initializer.call(l) : void 0, a.initializer = void 0), void 0 === a.initializer ? (Object.defineProperty(i, e, a), null) : a; }
  const __COLOCATED_TEMPLATE__ = (0, _templateFactory.createTemplateFactory)(
  /*
    <Highlight @selection={{this.highlightSelection}} @onSelect={{@onSelect}} class="d-flex flex-column">
    <Layout
      @size={{@deviceSize}}
      @model={{@model}}
      @page={{@model.page}}
      @conditions={{@conditions}}
      @onSelect={{@onSelect}}
      @path="page"
      as |layoutCol|
    >
      <View::Selector @cols={{@model.page.cols}} @layoutCol={{layoutCol}} as |col index name|>
        <View::Slot
          @space={{@model.space}}
          @state={{@state}}
          @onChangeState={{@onChangeState}}
          @model={{@model}}
          @value={{col}}
          @isEditor={{true}}
          @deviceSize={{@deviceSize}}
          @path={{concat "page.cols." index}}
          {{did-insert (fn this.ensureColExists name)}}
        />
      </View::Selector>
    </Layout>
  </Highlight>
  */
  {
    "id": "rt1Z71zw",
    "block": "[[[8,[39,0],[[24,0,\"d-flex flex-column\"]],[[\"@selection\",\"@onSelect\"],[[30,0,[\"highlightSelection\"]],[30,1]]],[[\"default\"],[[[[1,\"\\n  \"],[8,[39,1],null,[[\"@size\",\"@model\",\"@page\",\"@conditions\",\"@onSelect\",\"@path\"],[[30,2],[30,3],[30,3,[\"page\"]],[30,4],[30,1],\"page\"]],[[\"default\"],[[[[1,\"\\n    \"],[8,[39,2],null,[[\"@cols\",\"@layoutCol\"],[[30,3,[\"page\",\"cols\"]],[30,5]]],[[\"default\"],[[[[1,\"\\n      \"],[8,[39,3],[[4,[38,5],[[28,[37,6],[[30,0,[\"ensureColExists\"]],[30,8]],null]],null]],[[\"@space\",\"@state\",\"@onChangeState\",\"@model\",\"@value\",\"@isEditor\",\"@deviceSize\",\"@path\"],[[30,3,[\"space\"]],[30,9],[30,10],[30,3],[30,6],true,[30,2],[28,[37,4],[\"page.cols.\",[30,7]],null]]],null],[1,\"\\n    \"]],[6,7,8]]]]],[1,\"\\n  \"]],[5]]]]],[1,\"\\n\"]],[]]]]]],[\"@onSelect\",\"@deviceSize\",\"@model\",\"@conditions\",\"layoutCol\",\"col\",\"index\",\"name\",\"@state\",\"@onChangeState\"],false,[\"highlight\",\"layout\",\"view/selector\",\"view/slot\",\"concat\",\"did-insert\",\"fn\"]]",
    "moduleName": "spaces/components/page-frame-edit.hbs",
    "isStrictMode": false
  });
  let PageFrameEdit = _exports.default = (_class = class PageFrameEdit extends _component2.default {
    get highlightSelection() {
      if (!this.args.selection?.isHighlight) {
        return null;
      }
      return this.args.selection;
    }
    ensureColExists(name) {
      (0, _runloop.later)(() => {
        this.args.model.page?.upsertPageColByName(name);
      });
    }
  }, (_applyDecoratedDescriptor(_class.prototype, "ensureColExists", [_object.action], Object.getOwnPropertyDescriptor(_class.prototype, "ensureColExists"), _class.prototype)), _class);
  (0, _component.setComponentTemplate)(__COLOCATED_TEMPLATE__, PageFrameEdit);
});