define("manage/components/manage/field-container", ["exports", "@ember/component", "@glimmer/component", "@ember/object", "@glimmer/tracking", "@ember/runloop", "@ember/template-factory"], function (_exports, _component, _component2, _object, _tracking, _runloop, _templateFactory) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = _exports.ValueManager = void 0;
  var _class, _descriptor, _class2, _descriptor2, _descriptor3;
  function _initializerDefineProperty(e, i, r, l) { r && Object.defineProperty(e, i, { enumerable: r.enumerable, configurable: r.configurable, writable: r.writable, value: r.initializer ? r.initializer.call(l) : void 0 }); }
  function _defineProperty(e, r, t) { return (r = _toPropertyKey(r)) in e ? Object.defineProperty(e, r, { value: t, enumerable: !0, configurable: !0, writable: !0 }) : e[r] = t, e; }
  function _toPropertyKey(t) { var i = _toPrimitive(t, "string"); return "symbol" == typeof i ? i : i + ""; }
  function _toPrimitive(t, r) { if ("object" != typeof t || !t) return t; var e = t[Symbol.toPrimitive]; if (void 0 !== e) { var i = e.call(t, r || "default"); if ("object" != typeof i) return i; throw new TypeError("@@toPrimitive must return a primitive value."); } return ("string" === r ? String : Number)(t); }
  function _applyDecoratedDescriptor(i, e, r, n, l) { var a = {}; return Object.keys(n).forEach(function (i) { a[i] = n[i]; }), a.enumerable = !!a.enumerable, a.configurable = !!a.configurable, ("value" in a || a.initializer) && (a.writable = !0), a = r.slice().reverse().reduce(function (r, n) { return n(i, e, r) || r; }, a), l && void 0 !== a.initializer && (a.value = a.initializer ? a.initializer.call(l) : void 0, a.initializer = void 0), void 0 === a.initializer ? (Object.defineProperty(i, e, a), null) : a; }
  function _initializerWarningHelper(r, e) { throw Error("Decorating class property failed. Please ensure that transform-class-properties is enabled and runs after the decorators transform."); }
  const __COLOCATED_TEMPLATE__ = (0, _templateFactory.createTemplateFactory)(
  /*
    <div class="manage-field-container d-flex flex-wrap w-100 {{if @isDisabled "is-disabled"}}" ...attributes>
    {{#unless @isDisabled}}
      <div class="button-container">
        <button type="button" class="btn btn-edit {{if this.isEditMode "btn-primary" "btn-outline-secondary"}}"
          {{on "click" this.toggleMode}} disabled={{this.isDisabled}}>
          <FaIcon @icon="pencil-alt" />
        </button>
      </div>
    {{/unless}}
  
    <h3 class="panel-title">
      {{@title}}
  
      {{#if @help}}
        <HelpTooltip>
          {{@help}}
        </HelpTooltip>
      {{/if}}
    </h3>
  
    <div class="value-container w-100">
      <div class="{{if this.isEditMode "edit-container" "view-container"}} w-100">
        {{#if (and this.isEditMode this.hasEditValueReady)}}
          {{yield this.manager to="edit"}}
        {{else}}
          {{yield this.manager to="view"}}
        {{/if}}
  
        {{yield this.isEditMode to="both"}}
  
        {{#if this.isEditMode}}
          <Manage::RowSaveCancel class="mt-3 w-100" @isSaveDisabled={{@isSaveDisabled}} @onSave={{this.onSave}}
            @onCancel={{@onCancel}} {{did-insert this.setupManager}}/>
        {{/if}}
      </div>
    </div>
  </div>
  */
  {
    "id": "kHR2htbS",
    "block": "[[[11,0],[16,0,[29,[\"manage-field-container d-flex flex-wrap w-100 \",[52,[30,1],\"is-disabled\"]]]],[17,2],[12],[1,\"\\n\"],[41,[51,[30,1]],[[[1,\"    \"],[10,0],[14,0,\"button-container\"],[12],[1,\"\\n      \"],[11,\"button\"],[16,0,[29,[\"btn btn-edit \",[52,[30,0,[\"isEditMode\"]],\"btn-primary\",\"btn-outline-secondary\"]]]],[16,\"disabled\",[30,0,[\"isDisabled\"]]],[24,4,\"button\"],[4,[38,4],[\"click\",[30,0,[\"toggleMode\"]]],null],[12],[1,\"\\n        \"],[8,[39,5],null,[[\"@icon\"],[\"pencil-alt\"]],null],[1,\"\\n      \"],[13],[1,\"\\n    \"],[13],[1,\"\\n\"]],[]],null],[1,\"\\n  \"],[10,\"h3\"],[14,0,\"panel-title\"],[12],[1,\"\\n    \"],[1,[30,3]],[1,\"\\n\\n\"],[41,[30,4],[[[1,\"      \"],[8,[39,7],null,null,[[\"default\"],[[[[1,\"\\n        \"],[1,[30,4]],[1,\"\\n      \"]],[]]]]],[1,\"\\n\"]],[]],null],[1,\"  \"],[13],[1,\"\\n\\n  \"],[10,0],[14,0,\"value-container w-100\"],[12],[1,\"\\n    \"],[10,0],[15,0,[29,[[52,[30,0,[\"isEditMode\"]],\"edit-container\",\"view-container\"],\" w-100\"]]],[12],[1,\"\\n\"],[41,[28,[37,8],[[30,0,[\"isEditMode\"]],[30,0,[\"hasEditValueReady\"]]],null],[[[1,\"        \"],[18,7,[[30,0,[\"manager\"]]]],[1,\"\\n\"]],[]],[[[1,\"        \"],[18,8,[[30,0,[\"manager\"]]]],[1,\"\\n\"]],[]]],[1,\"\\n      \"],[18,9,[[30,0,[\"isEditMode\"]]]],[1,\"\\n\\n\"],[41,[30,0,[\"isEditMode\"]],[[[1,\"        \"],[8,[39,10],[[24,0,\"mt-3 w-100\"],[4,[38,11],[[30,0,[\"setupManager\"]]],null]],[[\"@isSaveDisabled\",\"@onSave\",\"@onCancel\"],[[30,5],[30,0,[\"onSave\"]],[30,6]]],null],[1,\"\\n\"]],[]],null],[1,\"    \"],[13],[1,\"\\n  \"],[13],[1,\"\\n\"],[13]],[\"@isDisabled\",\"&attrs\",\"@title\",\"@help\",\"@isSaveDisabled\",\"@onCancel\",\"&edit\",\"&view\",\"&both\"],false,[\"div\",\"if\",\"unless\",\"button\",\"on\",\"fa-icon\",\"h3\",\"help-tooltip\",\"and\",\"yield\",\"manage/row-save-cancel\",\"did-insert\"]]",
    "moduleName": "manage/components/manage/field-container.hbs",
    "isStrictMode": false
  });
  /**
    Copyright: © 2015-2024 GISCollective
    License: Subject to the terms of the AFFERO GENERAL PUBLIC LICENSE, as written in the included COPYING file.
  */
  let ValueManager = _exports.ValueManager = (_class = class ValueManager {
    constructor() {
      _initializerDefineProperty(this, "value", _descriptor, this);
    }
    change(newValue) {
      this.value = newValue;
    }
  }, (_descriptor = _applyDecoratedDescriptor(_class.prototype, "value", [_tracking.tracked], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _applyDecoratedDescriptor(_class.prototype, "change", [_object.action], Object.getOwnPropertyDescriptor(_class.prototype, "change"), _class.prototype)), _class);
  let ManageFieldContainerComponent = _exports.default = (_class2 = class ManageFieldContainerComponent extends _component2.default {
    constructor(...args) {
      super(...args);
      _initializerDefineProperty(this, "isDisabled", _descriptor2, this);
      _initializerDefineProperty(this, "manager", _descriptor3, this);
    }
    get isEditMode() {
      return this.args.title == this.args.editablePanel;
    }
    get hasEditValueReady() {
      if (this.args.value === undefined) {
        return true;
      }
      return this.manager.value !== undefined;
    }
    async onSave() {
      this.isDisabled = true;
      let result;
      try {
        result = await this.args.onSave(this.manager.value);
      } catch (err) {
        console.error(err);
        throw err;
      } finally {
        this.isDisabled = false;
      }
      this.args.onEdit?.('');
      return result;
    }
    setupManager() {
      if (this.manager.value) {
        return;
      }
      (0, _runloop.later)(() => {
        this.manager.value = this.args.value;
      });
    }
    toggleMode() {
      if (this.isEditMode) {
        this.manager.value = undefined;
        return this.args.onCancel(this.args.title);
      }
      this.manager.value = this.args.value;
      return this.args.onEdit(this.args.title);
    }
  }, (_descriptor2 = _applyDecoratedDescriptor(_class2.prototype, "isDisabled", [_tracking.tracked], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor3 = _applyDecoratedDescriptor(_class2.prototype, "manager", [_tracking.tracked], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: function () {
      return new ValueManager();
    }
  }), _applyDecoratedDescriptor(_class2.prototype, "onSave", [_object.action], Object.getOwnPropertyDescriptor(_class2.prototype, "onSave"), _class2.prototype), _applyDecoratedDescriptor(_class2.prototype, "setupManager", [_object.action], Object.getOwnPropertyDescriptor(_class2.prototype, "setupManager"), _class2.prototype), _applyDecoratedDescriptor(_class2.prototype, "toggleMode", [_object.action], Object.getOwnPropertyDescriptor(_class2.prototype, "toggleMode"), _class2.prototype)), _class2);
  (0, _component.setComponentTemplate)(__COLOCATED_TEMPLATE__, ManageFieldContainerComponent);
});